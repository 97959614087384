import React from "react";

const PublicServiceGuidelinesList = (props) => {
  return (
    <div className="mw9 center mt4 mb4 ph4-ns overflow-auto">
      <table className="f4 w-100 pa2" cellSpacing="0">
        <thead>
          <tr>
            <th className="fw6 bb b--black-20 tl pv3 pr3">No.</th>
            <th className="fw6 bb b--black-20 tl pv3 pr3">
              Nama Panduan Layanan Publik
            </th>
            <th className="fw6 bb b--black-20 tl pv3 pr3">Action</th>
          </tr>
        </thead>
        <tbody className="f4 lh-copy">
          {props.nodes.map((node, position) => (
            <tr key={node.id}>
              <td className="pv3 pr3 bb b--black-20">{position + 1}</td>
              <td className="pv3 pr3 bb b--black-20">
                {node.uploadedFile.caption}
              </td>
              <td className="pv3 pr3 bb b--black-20">
                <a
                  href={node.uploadedFile.asset.url}
                  download
                  className="gray hover-black-90 tracked ttu sans-serif f5"
                >
                  Download
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PublicServiceGuidelinesList;
